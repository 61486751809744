<template>
  <div
    :class="['input', { error: !!error, arrows: isWithArrows }]"
    class="input-container"
  >
    <!-- <label v-if="label" :for="inputID">{{ label }}</label> -->
    <div class="additional-items">
      <span v-if="!!max" class="max">
        <span
          :class="['current', { 'not-valid': !!this.isCurrentLengthValid }]"
          >{{ currentLength }}</span
        >/{{ max }}
      </span>
    </div>
    <textarea
      v-if="multiline"
      :id="inputID"
      :placeholder="placeholder"
      :maxlength="max"
      v-model="local"
      :readonly="readonly"
      @change="$emit('change', $event)"
      :style="{ height: height + 'px' }"
    ></textarea>
    <input
      v-else
      :id="inputID"
      @blur="leaveField()"
      :type="type"
      :placeholder="placeholder"
      :maxlength="max"
      :min="min"
      :style="{ 'max-width': width + 'px', padding: 5 + 'px' }"
      v-model="local"
      :readonly="readonly"
      @change="$emit('change', $event)"
    />
    <small v-if="error" class="error">{{ error }}</small>
    <small v-if="info" class="info">{{ info }}</small>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 30
    },
    value: {
      default: null
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    info: {
      type: String
    },
    multiline: {
      type: Boolean,
      default: false
    },
    max: {
      type: Number
    },
    min: {
      type: Number
    },
    width: {
      type: Number
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isWithArrows: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      local: this.value || '',
      inputID: null,
      currentLength: 0
    }
  },
  computed: {
    isCurrentLengthValid () {
      if (this.max) {
        return !(this.currentLength > 0 && this.currentLength < this.max)
      } else return true
    }
  },
  methods: {
    leaveField () {
      this.$emit('leaveField')
    }
  },
  watch: {
    value (v) {
      if (this.type === 'number') {
        if (this.min > parseFloat(v)) {
          v = this.min
        }
      }
      this.local = v
      this.currentLength = v.length
    },
    local (v) {
      this.$emit('input', v)
      if (v) this.currentLength = v.length
    }
  },
  mounted () {
    this.inputID =
      this.type +
      '_' +
      new Date().getTime() +
      '_' +
      Math.trunc(Math.random() * 1000)
  }
}
</script>

<style lang="scss" scoped>
.input-container{
  padding: 0;
  border: none;
}
.input {
  display: flex;
  flex-direction: column;

  > label {
    user-select: none;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 0.875em;
    line-height: 18px;
    color: #333333;
  }

  > .additional-items {
    position: relative;
    z-index: 10;

    > .max {
      position: absolute;
      top: 9px;
      right: 10px;
      color: #989898;

      .not-valid {
        color: red;
      }
    }
  }

  > input,
  > textarea {
    // padding: 7px;
    border: 1px solid $input-border;
    border-radius: 3px;
    transition: 0.3s;
    font-size: 0.875em;
    //height: 34px;
    //min-width: 300px;
    width: 100%;
    &:hover {
      border: 1px solid $input-border-hover;
    }

    background: #ffffff;
    box-shadow: inset 3px 3px 2px rgba(0, 0, 0, 0.02);
    line-height: 22px;
    outline: none;
    &:disabled {
      background: #f9f9f9;
      border: 1px solid #cccccc;
    }
    &:focus {
      border: 1px solid $input-border-focus;
    }
    &:not(.arrows) {
      /* Google Chrome */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type='number'] {
        -moz-appearance: textfield;
      }
    }
  }

  &.error > input,
  &.error > textarea {
    border: 1px solid $red-light;
  }

  > small {
    font-size: 0.875em;
    line-height: 20px;
  }

  > small.error {
    margin-top: 2px;
    display: none;
    color: red;
  }

  &.error > small.error {
    display: block;
  }

  > small.info {
    margin-top: 12px;
    display: block;
    color: #777777;
  }
}
</style>
